/**
* Creates a row to indicate no results exist.
* This will only show if the backing model (i.e. no-results-row="ctrl.model")
* is an empty array or hash. If it is undefined, this row is hidden (to allow
* it to be hidden while results are loading)
*/
angular.module('app')
    .directive("noResultsRow", function () {
        return {
            restrict: "A",
            scope: {
            	noResultsRow: '='
            },
            template: 'No results',
            link: function(scope, element, attrs){
            	scope.$watchCollection('noResultsRow', function(newVal, oldVal){
            		var show = newVal && _.isEmpty(newVal);
            		element.css("display", show ? "block" : "none");
            	});
            }
        };
    });
